import React, { useEffect } from "react";
import styles from '../../scss/CollapsibleMap.module.scss';
import '../../scss/defaultFonts.scss';

interface CompanyProps {
    companys: any;
}

interface MarkerData {
    position: { lat: number, lng: number },
    title: string,
    icon: string,
    content: string,
}
const CollapsibleMap = (props: CompanyProps) => {

    const markerData: MarkerData[] = props.companys.map((company: any) => {
        if (company.data.coordinates?.iv?.latitude && company.data.coordinates?.iv?.longitude) {
            const content = `<div key="${company.data.id.iv}">
                <div style="font-size: 20px; font-weight: bold; margin-bottom: 20px"> ${company.data.name.iv} </div>
                <div>
                    <p style="margin:0; font-size:14px">${company.data.contact.iv.street ? company.data.contact.iv.street : ''}</p>
                    <p style="margin:0; font-size:14px">${company.data.contact.iv['zip-code'] ? company.data.contact.iv['zip-code'] : ''}</p>
                    <p style="margin:0; font-size:14px">${company.data.contact.iv.telefon ? 'Tel.: ' + company.data.contact.iv.telefon : ''}</p>
                    <p style="margin:0; font-size:14px">${company.data.contact.iv.fax ? 'Fax: ' + company.data.contact.iv.fax : ''}</p>
                    <p style="margin:0; font-size:14px">${company.data.contact.iv.email ? company.data.contact.iv.email : ''}</p>
                </div> <br />
                <div><a class="mapButton" href="/unternehmen/${company.data.name.iv.toLowerCase().replaceAll(' ', '-').replaceAll('%20', '-')}" style="font-size: 16px">Mehr ...</a></div>
                </div>`;
            return {
                position: { lat: company.data.coordinates.iv.latitude, lng: company.data.coordinates.iv.longitude },
                title: company.data.name.iv,
                icon: 'images/SiSa_GeoPin.png',
                content: content,
            }
        }
    });

    const markers:any = [];
    const infolables:any = [];

    useEffect(() => {        
        const google = (window as any).google;

        function initMap(): void {
            const map = new google.maps.Map(document.getElementById("CollapsibleMapId") as HTMLElement, {
                center: { lat: 52.399, lng: 13.078 },
                zoom: 13,
            });
            markerData.map((marker, i) => {
                if (!marker) {return;}
                markers[i] = new google.maps.Marker({
                    position: marker.position,
                    map: map,
                    title: marker.title,
                    icon: marker.icon,
                }); 
                infolables[i] = new google.maps.InfoWindow({
                    content: marker.content,
                });
                markers[i].addListener("click", () => {
                    infolables[i].open({
                        anchor: markers[i],
                        map,
                        shouldFocus: false,
                    });
                });
            })
        }

        initMap();

        function dontUse():void {
            let newMarker = new google.maps.Marker({
                position: { lat: 52.412, lng: 13.062 },
                //map: map,
                title: 'Test Marker',
                icon: 'images/SiSa_GeoPin.png',
            }); 
            const contentString =
                '<h3 id="firstHeading" class="firstHeading">Uluru</h3>' +
                '<p>Attribution: Uluru, <a href="https://en.wikipedia.org/w/index.php?title=Uluru&oldid=297882194">';

            let newInfowindow = new google.maps.InfoWindow({
                content: contentString,
            });
            newMarker.addListener("click", () => {
                newInfowindow.open({
                    anchor: newMarker,
                    //map,
                    shouldFocus: false,
                });
            });
        }

    }, [props.companys]);

    return (
        <>
            <div className={styles.CollapsibleMapContainer}>

                <div className={styles.ButtonContainer}>
                    <button
                        onClick={() => {
                            const content = document.getElementById('CollapsibleMapContainer');
                            if (!content){return;}
                            if (content.style.width != '0px'){
                                content.style.width = '0px';
                            } else {
                                content.style.width = '85vw';
                            }}
                        }
                    >
                        <h3>Find&nbsp;&nbsp;Us</h3>
                    </button>
                </div>

                <div id='CollapsibleMapContainer' className={styles.MapContainer} style={{width: '0px'}}>
                    <div 
                        id='CollapsibleMapId' 
                        className={styles.Map}
                    />
                </div>

            </div>
        </>
    );
}

export default CollapsibleMap;
