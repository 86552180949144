import React, {useEffect, useState} from 'react';
import ProjektePageArticle from './ProjektePage/ProjektePageArticle';
import {fetchProjects} from '../../helperFunctions/squidexFetchApi';
import styles from '../../../scss/LandingPage.module.scss';
import '../../../scss/defaultFonts.scss';

const ProjektePage = () => {
    const [status, setStatus] = useState('Loading');
    const [projects, setProjects] = useState<any>([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchProjects().then(setProjects);
    }, []);

    useEffect(() => {
        if (projects.length > 0) {
            setStatus('Success');
        }
    }, [projects]);

    return (
    <div className={styles.SubPage}>
        <h2 className='centerText'>
            Unser Engagement
        </h2>
        <h3 className='centerText'> Alle Projekte im Überblick. </h3>

        <div className={'ProjekteListContainer'}>
            {status === 'Loading' && <div>Loading...</div>}
            {status === 'Error' && <div>There was an error</div>}
            {status === 'Success'&& projects.map((project: any) =>
                <ProjektePageArticle
                    title={project.data.title.iv}
                    imagepath={project.data.teaser.iv[0]}
                    projectName={project.data.name.iv}
                />
            )}
        </div>
    </div>
    );
};

export default ProjektePage;