import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../scss/Footer.module.scss';

const Footer = () => {
    return (
        <div className={styles.Container}>
            
            <div className={styles.listFlex3}><br/></div>

            <div className={styles.list}>
                <Link
                    to='/' className={styles.logo}
                    onClick={() => {
                        window.scrollTo({top: 0, behavior: "smooth"});
                    }}
                >
                    <img src={'/images/logo_invert.png'}/>
                </Link>

                <br />
                <Link to={'/imprint'}>Impressum</Link>
                <Link to={'/privacy'}>Datenschutz</Link>
                <Link to={'/mitglied-werden'}>Mitglied werden</Link>
            </div>

            <div className={styles.listFlex3}><br/><br/></div>

            <div className={styles.list}>
                <p>Silicon Sanssouci e.V.</p>
                <p>c/o D-LABS GmbH </p>
                <p>Marlene-Dietrich-Allee 15</p>
                <p>D-14482 Potsdam, Germany</p>
            </div>

            <div className={styles.listFlex1}><br/><br/></div>

            <div className={styles.list}>
                <p>+49 331 97992 300</p>
                <p>info@silicon-sanssouci.org</p>
            </div>

            <div className={styles.listFlex3} />
        </div>
    );
}

export default Footer;
