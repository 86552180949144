import React, {useEffect, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import ProjekteArticle from './ProjektePage/ProjekteArticle';
import {fetchMainEngagements, fetchProjects} from '../../helperFunctions/squidexFetchApi';
import styles from '../../../scss/LandingPage.module.scss';
import '../../../scss/defaultFonts.scss';
import { Autoplay } from 'swiper';

const EngagementPage = () => {
    const [status, setStatus] = useState('Loading');
    const [projects, setProjects] = useState<any>([]);
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        fetchProjects().then(setProjects);
        fetchMainEngagements().then(setData);
    }, []);

    useEffect(() => {
        if (projects.length > 0) {
            setStatus('Success');
        }
    }, [projects]);


    return (
    <>
        <div className={styles.Page}>
        <div className={styles.ProjektePage}>
            <h2 id={'engagement'} dangerouslySetInnerHTML={{__html: data?.title?.iv?.replaceAll('\n', '<br />')}} />
            <h3 dangerouslySetInnerHTML={{__html: data?.subtitle?.iv?.replaceAll('\n', '<br />')}} />
            <div dangerouslySetInnerHTML={{__html: data?.text?.iv}} />

                <div>
                    <Swiper
                        id={'SwiperNewsPage_LandingPage'}
                        spaceBetween={50}
                        breakpoints={{
                            100: {
                                slidesPerView: 1.2,
                            },
                            900: {
                                slidesPerView: 2.2,
                            },
                            1350: {
                                slidesPerView: 3.2,
                            },
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                    >
                        {status === 'Loading' && <div>Loading...</div>}
                        {status === 'Error' && <div>There was an error</div>}
                        {status === 'Success'&& projects.map((project: any) =>
                            <SwiperSlide>
                                <ProjekteArticle
                                    title={project.data.title.iv}
                                    imagepath={project.data.teaser?.iv?.[0]}
                                    projectName={project.data.title.iv}
                                />
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </div>
        </div>
    </>
    );
};

export default EngagementPage;
