import React from 'react';
import { Outlet } from 'react-router';
import Header from '../common/Header';
import Footer from '../common/Footer';

const MainPage = () => { 
    return (
    <>
        <Header />

        <Outlet />

        <Footer />
    </>
    );
};

export default MainPage;