import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {fetchMainPageGoals} from '../../helperFunctions/squidexFetchApi';
import styles from '../../../scss/LandingPage.module.scss';
import buttonStyles from '../../../scss/Buttons.module.scss';
import '../../../scss/defaultFonts.scss';

const MissionPage = () => {
    const navigate = useNavigate();
    const [displayMore, setDisplayMore] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    useEffect(() => {
        fetchMainPageGoals().then(setData);
    }, []);

    return (
    <>
        <div className={styles.Page}>
            <div className={styles.ZielePage}>
                <h2 id={'ziele'} dangerouslySetInnerHTML={{__html: data?.title?.iv.replaceAll('\n', '<br />')}} />
                <div dangerouslySetInnerHTML={{__html: data?.subtitle?.iv}} />
                {displayMore &&
                    <div dangerouslySetInnerHTML={{__html: data?.text?.iv}} />
                }
                <div>
                    <button 
                        className={buttonStyles.DefaultButton}
                        onClick={() => {
                            setDisplayMore(!displayMore);
                        }}
                        disabled={false}
                    >
                        {displayMore ? 'Weniger' : 'Mehr' }
                    </button>
                </div>
            </div>
        </div>
    </>
    );
};

export default MissionPage;
