import React, {useEffect, useState} from 'react';
import {Navigate, useParams} from 'react-router';
import {fetchProjects} from '../../../helperFunctions/squidexFetchApi';
import styles from '../../../../scss/DetailsPage.module.scss';
import '../../../../scss/defaultFonts.scss';

const ProjektDetails = () => {
    const params = useParams<{ name: string }>();
    const [project, setProject] = useState<any>();
    const [projects, setProjects] = useState<any>();
    const [redirect, setRedirect] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchProjects().then(setProjects);
    }, []);

    useEffect(() => {},[project]);

    useEffect(() => {
        if (!params.name || !projects) {setProject(undefined); return;}
        let projectFound = false;
        projects.forEach((project:any) => {
            if (project.data.title.iv.toLowerCase().replaceAll(' ', '-').replaceAll('%20', '-') == params.name) {
                setProject(project.data);
                projectFound = true;
            }
        })
        if (!projectFound) {
            setRedirect(true);
        }
    },[projects]);

    return (
        <div className={styles.DetailPage}>
            {redirect &&
                <Navigate replace to="/404" />
            }
            {project &&
                <>
                    <div className={styles.ContentContainer}>
                        <h3>{project.title.iv}</h3>
                        <div dangerouslySetInnerHTML={{__html: project.text.iv}}/>
                    </div>
                    <div className={styles.SideContainer}>
                        {project.teaser?.iv?.[0] &&
                            <>
                                <img src={project.teaser?.iv?.[0] ? project.teaser.iv[0].includes('https') ?
                                    project.logo.iv[0] :
                                    'https://squidex.inseciacloud.com/api/assets/silicon-sanssouci/' +
                                    project.teaser.iv[0] +'/sprind.png?' : ''}
                                     alt={'News Image'}
                                />
                                <br />
                            </>
                        }
                        {project.teaser?.iv?.[1] &&
                            <>
                                <img src={project.teaser?.iv?.[1] ? project.teaser.iv[1].includes('https') ?
                                    project.logo.iv[1] :
                                    'https://squidex.inseciacloud.com/api/assets/silicon-sanssouci/' +
                                    project.teaser.iv[1] +'/sprind.png?' : ''}
                                     alt={'News Image'}
                                />
                                <br />
                            </>
                        }
                        {project.teaser?.iv?.[2] &&
                            <>
                                <img src={project.teaser?.iv?.[2] ? project.teaser.iv[2].includes('https') ?
                                    project.logo.iv[2] :
                                    'https://squidex.inseciacloud.com/api/assets/silicon-sanssouci/' +
                                    project.teaser.iv[2] +'/sprind.png?' : ''}
                                     alt={'News Image'}
                                />
                                <br />
                            </>
                        }
                        <p>{project.description.iv }</p>

                    </div>
                </>
            }
        </div>
    );
};

export default ProjektDetails;
