import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainPage from './components/pages/MainPage';
import LandingPage from './components/pages/LandingPage';
import LocationPage from './components/pages/subPages/LocationPage';
import MitgliedWerden from './components/pages/subPages/MitgliedWerdenPage';
import Satzung from './components/pages/subPages/SatzungPage';
import Unternehmen from './components/pages/subPages/UnternehmenPage';
import Koepfe from './components/pages/subPages/KoepfePage';
import Projekte from './components/pages/subPages/ProjektePage';
import UnternehmenDetails from './components/pages/subPages/UnternehmenPage/UnternehmenDetails';
import ProjektDetails from './components/pages/subPages/ProjektePage/ProjektDetails';
import FormPage from './components/pages/subPages/FormPage';
import ImprintPage from './components/pages/subPages/ImprintPage';
import PrivacyPage from './components/pages/subPages/PrivacyPage';
import _404Page from './components/pages/subPages/_404Page';
import {Navigate} from 'react-router';

const Main = () => { 

    return (
        <Routes>
            <Route path={'/'} element={<MainPage />}>
                <Route index element={<LandingPage />} />
                <Route path={'/mitglied-werden'} element={<MitgliedWerden />} />
                <Route path={'/satzung'} element={<Satzung />} />
                <Route path={'/it-standort'} element={<LocationPage />} />
                <Route path={'/Unternehmen'} element={<Unternehmen />} />
                <Route path={'/Unternehmen/:name'} element={<UnternehmenDetails />} />
                <Route path={'/koepfe'} element={<Koepfe />} />
                <Route path={'/engagement'} element={<Projekte />} />
                <Route path={'/engagement/:name'} element={<ProjektDetails />} />
                {
                    // todo add control to squidex, for event-form display, content and improve mail serv handling for dynamic content
                        // todo... (just string to email server and configuring handles the FE?)
                    // <Route path={'/event-form'} element={<FormPage/>}/>
                }
                <Route path={'/imprint'} element={<ImprintPage />} />
                <Route path={'/privacy'} element={<PrivacyPage />} />

                <Route path={'/404'} element={<_404Page />} />
                <Route path={'*'} element={<Navigate replace to="/404" />} />
            </Route>
        </Routes>
    );
};

export default Main;
