import React, {useEffect, useState} from 'react';
import {fetch404Page} from '../../helperFunctions/squidexFetchApi';
import styles from '../../../scss/LandingPage.module.scss';
import '../../../scss/defaultFonts.scss';

const _404Page = () => {
    const [data, setData] = useState<any>([]);
    useEffect(() => {
        fetch404Page().then(setData);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={styles.SubPage}>
            <h2 className='centerText' dangerouslySetInnerHTML={{__html: data?.title?.iv.replaceAll('\n', '<br />')}} />
            <h3 className='centerText' dangerouslySetInnerHTML={{__html: data?.subtitle?.iv.replaceAll('\n', '<br />')}} />

            <div dangerouslySetInnerHTML={{__html: data?.text?.iv}} />

        </div>
    );
};

export default _404Page;