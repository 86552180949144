import React from "react";

const getSquidexElementByValue = async (category: string, field: string, value: string): Promise<any> => {
    const response = await fetch(`https://squidex.inseciacloud.com/api/content/silicon-sanssouci/${category}?$filter=data/${field}/iv%20eq%20%27${value}%27`);
    const data = await response.json();
    return data.items[0]?.data;
};
const getAllSquidexElemetsByCategory = async (category: string): Promise<any> => {
    const response = await fetch(`https://squidex.inseciacloud.com/api/content/silicon-sanssouci/${category}`);
    const data = await response.json();
    return data.items;
};


const fetchCompanys = getAllSquidexElemetsByCategory.bind(undefined, 'company');
const fetchCompanyById = getSquidexElementByValue.bind(undefined, 'company', 'id');
const fetchProjects = getAllSquidexElemetsByCategory.bind(undefined, 'project');
const fetchProjectById = getSquidexElementByValue.bind(undefined, 'project', 'id');
const fetchNews = getAllSquidexElemetsByCategory.bind(undefined, 'news');
const fetchNewsById = getSquidexElementByValue.bind(undefined, 'news', 'id');
const fetchMarquee = getAllSquidexElemetsByCategory.bind(undefined, 'marquee');

// main page content
const fetchMainPageStart = getSquidexElementByValue.bind(undefined, 'main-contents', 'id', 'start');
const fetchMainPageGoals = getSquidexElementByValue.bind(undefined, 'main-contents', 'id', 'goals');
const fetchMainPageCompanys = getSquidexElementByValue.bind(undefined, 'main-contents', 'id', 'companys');
const fetchMainPageHeads = getSquidexElementByValue.bind(undefined, 'main-contents', 'id', 'heads');
const fetchMainEngagements = getSquidexElementByValue.bind(undefined, 'main-contents', 'id', 'engagement');

// subpage content
const fetchDownloadsPage = getSquidexElementByValue.bind(undefined,'pages', 'id', 'downloads');
const fetchMembershipPage = getSquidexElementByValue.bind(undefined, 'pages', 'id', 'membership');
const fetchStatutePage = getSquidexElementByValue.bind(undefined, 'pages', 'id', 'statute');
const fetchGoalsPage = getSquidexElementByValue.bind(undefined, 'pages', 'id', 'goals');
const fetchLocationPage = getSquidexElementByValue.bind(undefined, 'pages', 'id', 'location');
const fetchImprintPage = getSquidexElementByValue.bind(undefined, 'pages', 'id', 'imprint');
const fetchPrivacyPage = getSquidexElementByValue.bind(undefined, 'pages', 'id', 'privacy');
const fetch404Page = getSquidexElementByValue.bind(undefined, 'pages', 'id', '404');

export {
    fetchCompanys, fetchCompanyById,
    fetchProjects, fetchProjectById,
    fetchNews, fetchNewsById,
    fetchMarquee,
    fetchMainPageStart, fetchMainPageGoals, fetchMainPageCompanys, fetchMainPageHeads, fetchMainEngagements,
    fetchDownloadsPage, fetchMembershipPage, fetchStatutePage, fetchGoalsPage, fetchLocationPage, fetchImprintPage, fetchPrivacyPage, fetch404Page,
};
