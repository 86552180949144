import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import CompanyBubble from '../../common/CompanyBubble';
import {fetchMainPageCompanys} from '../../helperFunctions/squidexFetchApi';
import styles from '../../../scss/LandingPage.module.scss';
import buttonStyles from '../../../scss/Buttons.module.scss';
import '../../../scss/defaultFonts.scss';

interface UnternehmensProps {
    companys: any;
}

const CompanyPage = (props: UnternehmensProps) => {
    const [data, setData] = useState<any>([]);
    useEffect(() => {
        fetchMainPageCompanys().then(setData);
    }, []);

    return (
    <>
        <div className={styles.Page}>
            <div className={styles.UnternehmenPage}>
                <h2 id={'unternehmen'} dangerouslySetInnerHTML={{__html: data?.title?.iv.replaceAll('\n', '<br />')}} />
                <div dangerouslySetInnerHTML={{__html: data?.text?.iv}} />
                
                <div id='CompanyBubbleContainerId' className={styles.UnternehmensContainer}>
                    {props.companys.map((company: any) =>
                        <CompanyBubble
                            key={company.data.id.iv}
                            imagepath={company.data.logo.iv? company.data.logo.iv[0]: 'placeholder'}
                            companyName={company.data.name.iv}
                        />
                    )}
                    <br />
                    <p style={{width: "100%"}}>Wollen Sie Potsdam voranbringen? <Link to={'/mitglied-werden'}>Jetzt Mitglied werden!</Link></p>
                    <br />
                </div>

                
                <div className={buttonStyles.ButtonContainerCenter}>
                    <button
                        id='CompanyBubbleButtonId'
                        className={buttonStyles.DefaultButton}
                        onClick={() => {
                            var element = document.getElementById('CompanyBubbleContainerId');
                            if( element?.className.includes('UnternehmensContainerShowMore') ){
                                element.classList.remove(styles.UnternehmensContainerShowMore);
                                document.getElementById('CompanyBubbleButtonId')!.innerHTML = 'Zeige Alles';
                            } else {
                                element?.classList.add(styles.UnternehmensContainerShowMore);
                                document.getElementById('CompanyBubbleButtonId')!.innerHTML = 'Zeige weniger';
                            }
                        }}
                        disabled={false}
                    >
                        Zeige Alles
                    </button>
                </div>
            </div>
        </div>
    </>
    );
};

export default CompanyPage;
