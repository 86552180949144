import React, {useEffect, useState} from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Marquee from 'react-fast-marquee';
import styles from '../../scss/Header.module.scss';
import {fetchMarquee, fetchStatutePage} from '../helperFunctions/squidexFetchApi';

const Header = () => {
    const [data, setData] = useState<any>([]);
    useEffect(() => {
        fetchMarquee().then(setData);
    }, []);

    return (
    <>
    <div className={styles.HeaderContainer}>

        {data[0]?.data?.display?.iv && <Marquee
            className={styles.marquee}
            loop={0}
            play={true}
            pauseOnHover={true}
            direction={'left'}
            speed={40}
            delay={5}
            gradient={false}
        >
            <div dangerouslySetInnerHTML={{__html: data[0]?.data?.content?.iv}}/>
        </Marquee>}

        <div className={styles.Container} >
            <Link to='/' className={styles.logo}>
                <img src={'/images/logo.png'} alt={'logo'}/>
            </Link>
            <div className={styles.list}>
                <Link to='/mitglied-werden' className={styles.link} >
                    Mitglied werden
                </Link>
                <Link to='/satzung' className={styles.link} >
                    Satzung
                </Link>
                <Link to='/#ziele' className={styles.link} >
                    Ziele
                </Link>
                <Link to='/#unternehmen' className={styles.link} >
                    Unternehmen
                </Link>
                <Link to='/#engagement' className={styles.link} >
                    Engagement
                </Link>
            </div>
            <div style={{ flex: 1 }} />
        </div>

    </div>
        {data[0]?.data?.display?.iv &&
            <>
                <br />
                <br />
                <br />
            </>
        }
    </>
    );
};

export default Header;
