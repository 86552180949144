import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import CollapsibleMap from '../../common/CollapsibleMap';
import styles from '../../../scss/LandingPage.module.scss';
import buttonStyles from '../../../scss/Buttons.module.scss';
import '../../../scss/defaultFonts.scss';
import {fetchMainPageStart} from "../../helperFunctions/squidexFetchApi";

interface CompanyProps {
    companys: any;
}
const StartPage = (props: CompanyProps) => {
    const navigate = useNavigate();
    const [data, setData] = useState<any>([]);
    useEffect(() => {
        fetchMainPageStart().then(setData);
    }, []);

    return (
    <>
        <CollapsibleMap companys={props.companys}/>

        <div className={styles.StartPage}>
            <h1 className='centerText' dangerouslySetInnerHTML={{__html: data?.title?.iv.replaceAll('\n', '<br />')}} />
            <h2 className='centerText' dangerouslySetInnerHTML={{__html: data?.subtitle?.iv.replaceAll('\n', '<br />')}} />
            <div dangerouslySetInnerHTML={{__html: data?.text?.iv}} />
            
            <div className={buttonStyles.ButtonContainerCenter}>
                <button 
                    className={buttonStyles.DefaultButton}
                    onClick={() => navigate('/it-standort')}
                    disabled={false}
                >
                    MEHR
                </button>
            </div>
        </div>
    </>
    );
};

export default StartPage;
