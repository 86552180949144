import React from "react";
import styles from '../../scss/CompanyBubble.module.scss';
import {Link} from "react-router-dom";

interface CompanyBubbleProps {
    imagepath: string;
    companyName: string;
}

const CompanyBubble = (props: CompanyBubbleProps) => {
    const target = `/unternehmen/` + props.companyName.toLowerCase().replaceAll(' ', '-').replaceAll('%20', '-');

    const imagePathSmall = props.imagepath.includes('https') ? props.imagepath :
        'https://squidex.inseciacloud.com/api/assets/silicon-sanssouci/' +
        props.imagepath +
        '/sprind.png?';
    const imagePathLarge = props.imagepath.includes('https') ? props.imagepath :
        'https://squidex.inseciacloud.com/api/assets/silicon-sanssouci/' +
        props.imagepath +
        '/sprind.png?';

    return (
    <>
        <Link className={styles.CompanyContainerLink} to={target}>
            <div
                className={styles.CompanyContainer}
            >
                    {props.imagepath && props.imagepath !== 'placeholder' &&
                        <img srcSet={imagePathSmall + ' 1x, ' + imagePathLarge + ' 2x'} src={imagePathSmall} alt={'News Image'}/>
                    }
            </div>
        </Link>
    </>
    );
};

export default CompanyBubble;