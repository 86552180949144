import React from "react";
import styles from '../../../../scss/LandingPage.module.scss';
import '../../../../scss/defaultFonts.scss';
import {Link} from "react-router-dom";

interface ProjekteArticleProps {
    title: string;
    imagepath: string;
    projectName: string;
}
const ProjekteArticle = (props: ProjekteArticleProps) => {
    const target = `/engagement/` + props.projectName.toLowerCase().replaceAll(' ', '-').replaceAll('%20', '-');
    let imagePathSmall = '';
    let imagePathLarge = '';
    if (props.imagepath) {
        imagePathSmall = props.imagepath.includes('https') ? props.imagepath :
            'https://squidex.inseciacloud.com/api/assets/silicon-sanssouci/' +
            props.imagepath +
            '/sprind.png?height=202&mode=Pad';
        imagePathLarge = props.imagepath.includes('https') ? props.imagepath :
            'https://squidex.inseciacloud.com/api/assets/silicon-sanssouci/' +
            props.imagepath +
            '/sprind.png?height=404&mode=Pad';
    }

    return (
    <>
        <Link style={{textDecoration: 'none'}} to={target}>
            <div
                className={styles.ProjekteArticle}
            >
                {/*
                props.imagepath &&
                    <img srcSet={imagePathSmall + ' 1x, ' + imagePathLarge + ' 2x'} src={imagePathSmall} alt={'Project Logo'}/>
                */}
                <div className={styles.ProjektTextBlock}>
                    <h3>{props.title}</h3>
                </div>
            </div>
        </Link>
    </>
    );
};

export default ProjekteArticle;
