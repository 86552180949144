import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {fetchMembershipPage} from '../../helperFunctions/squidexFetchApi';
import styles from '../../../scss/LandingPage.module.scss';
import '../../../scss/defaultFonts.scss';

const MitgliedWerden = () => {
    const [data, setData] = useState<any>([]);
    useEffect(() => {
        fetchMembershipPage().then(setData);
    }, []);
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
    <div className={styles.SubPage}>
        <h2 className='centerText' dangerouslySetInnerHTML={{__html: data?.title?.iv.replaceAll('\n', '<br />')}} />
        <h3 className='centerText' dangerouslySetInnerHTML={{__html: data?.subtitle?.iv.replaceAll('\n', '<br />')}} />

        <div dangerouslySetInnerHTML={{__html: data?.text?.iv}} />

    </div>
    );
};

export default MitgliedWerden;
