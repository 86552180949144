import React, {useEffect, useState} from 'react';
import CompanyBubble from '../../common/CompanyBubble';
import {fetchCompanys} from '../../helperFunctions/squidexFetchApi';
import styles from '../../../scss/LandingPage.module.scss';
import '../../../scss/defaultFonts.scss';

const UnternehmenPage = () => {
    const [companys, setCompanys] = useState<any>([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchCompanys().then(setCompanys);
    }, []);

    return (
    <div className={styles.SubPage}>
        <h2 id={'unternehmen'} className='centerText'>
            Unternehmen
        </h2>
        <h3 className='centerText'>
            Alle Unternehmen im Netzwerk „Silicon Sanssouci“ eint die Absicht, die Qualität der Landeshauptstadt
            Brandenburgs als ein Zentrum der Informations- und Kommunikationstechnologie mehr in den Fokus
            der Öffentlichkeit zu rücken. Ob kleine Firma oder etabliertes Großunternehmen, sie alle postulieren:
            „Wir sind Potsdam!“
        </h3>

        <div id='CompanyBubbleContainerId' className={styles.UnternehmensContainerAll}>
            {companys.map((company: any) =>
                <CompanyBubble
                    imagepath={company.data.logo.iv ? company.data.logo.iv[0] : 'placeholder'}
                    companyName={company.data.name.iv}
                />
            )}
        </div>
    </div>
    );
};

export default UnternehmenPage;
