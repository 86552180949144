import React, {useEffect, useState} from 'react';
import {Navigate, useParams} from 'react-router';
import {fetchCompanys} from '../../../helperFunctions/squidexFetchApi';
import styles from '../../../../scss/DetailsPage.module.scss';
import avatarStyles from '../../../../scss/AvatarBubble.module.scss';
import '../../../../scss/defaultFonts.scss';

const UnternehmenDetails = () => {
    const params = useParams<{ name: string }>();
    const [company, setCompany] = useState<any>();
    const [companys, setCompanys] = useState<any>();
    const [redirect, setRedirect] = useState<boolean>(false);

    const imagePathSmall = company ? company.head.iv.image?.length > 0 ? company.head.iv.image[0].includes('https') ? company.head.iv.image[0] :
        'https://squidex.inseciacloud.com/api/assets/silicon-sanssouci/' +
        company.head.iv.image[0] +
        '/sprind.png?height=202&width=202&mode=Pad' : '' : '';
    const imagePathLarge = company ? company.head.iv.image?.length > 0 ? company.head.iv.image[0].includes('https') ? company.head.iv.image[0] :
        'https://squidex.inseciacloud.com/api/assets/silicon-sanssouci/' +
        company.head.iv.image[0] +
        '/sprind.png?height=404&width=404&mode=Pad' : '' : '';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchCompanys().then((data: any) => {setCompanys(data); console.log(data)});
    }, []);

    useEffect(() => {},[company]);

    useEffect(() => {
        if (!params.name || !companys) {setCompany(undefined); return;}
        let companyFound = false;
        companys.forEach((company:any) => {
            if (company.data.name.iv.toLowerCase().replaceAll(' ', '-').replaceAll('%20', '-') == params.name) {
                setCompany(company.data);
                companyFound = true;
            }
        })
        if (!companyFound) {
            setRedirect(true);
        }
    },[companys]);

    return (
        <div className={styles.DetailPage}>
            {redirect &&
                <Navigate replace to="/404" />
            }
            {company &&
                <>
                    <div className={styles.ContentContainer}>
                        <h3>{company.name.iv}</h3>
                        <div dangerouslySetInnerHTML={{__html: company.description.iv}}/>
                    </div>
                    <div className={styles.SideContainer}>
                        {company.logo?.iv?.length > 0 &&
                            <img src={company.logo.iv[0].includes('https') ? company.logo.iv[0] :
                                'https://squidex.inseciacloud.com/api/assets/silicon-sanssouci/' +
                                company.logo.iv[0] + '/sprind.png?'}
                                 alt={'Company Image'}
                            />
                        }
                        <h4>Kontakt</h4>
                        <p>{company.contact.iv.street ? company.contact.iv.street : ''}</p>
                        <p>{company.contact.iv['zip-code'] ? company.contact.iv['zip-code'] : ''}</p>
                        <p>{company.contact.iv.telefon ? 'Tel.: ' + company.contact.iv.telefon : ''}</p>
                        <p>{company.contact.iv.fax ? 'Fax: ' + company.contact.iv.fax : ''}</p>
                        <p>{company.contact.iv.email ? company.contact.iv.email : ''}</p>

                        <h3 style={{marginBottom: '5px'}}>Ansprechpartner</h3>
                        {company.head.iv.image?.[0] &&
                            <div className={avatarStyles.Container} >
                                <div
                                    className={avatarStyles.AvatarContainer}
                                >
                                    <img
                                        style={{margin: 0}}
                                        srcSet={imagePathSmall + ' 1x, ' + imagePathLarge + ' 2x'}
                                        src={imagePathSmall} alt={'News Image'}
                                    />
                                </div>
                            </div>
                        }
                        <h4 style={{marginBottom: '0px'}}>{company.head.iv.name}</h4>
                        <p style={{marginBottom: '22px'}}>{company.head.iv.description}</p>
                        <p>{company.head.iv.telefon ? company.head.iv.telefon : ''}</p>
                        <p style={{marginBottom: '30px'}}>{company.head.iv.email ? company.head.iv.email : ''}</p>

                        <div className={styles.CompanySocialButtonContainer}>
                            {company.contact.iv.twitter &&
                                <a
                                    href={company.contact.iv.twitter}
                                    target={'_blank'}
                                >
                                    <img
                                        src={'/images/twitter_icon.svg'}
                                        alt={'twitter profile'}
                                        style={{width: '35px', height: '35px'}}
                                    />
                                </a>
                            }
                            {company.contact.iv.linkedin &&
                                <a
                                    href={company.contact.iv.linkedin}
                                    target={'_blank'}
                                >
                                    <img
                                        src={'/images/linkedin_icon.svg'}
                                        alt={'linkedin profile'}
                                        style={{width: '35px', height: '35px'}}
                                    />
                                </a>
                            }
                            {company.contact.iv.website &&
                                <a
                                    href={company.contact.iv.website}
                                    target={'_blank'}
                                >
                                    <img
                                        src={'/images/web_icon.svg'}
                                        alt={'website'}
                                        style={{width: '35px', height: '35px'}}
                                    />
                                </a>
                            }

                        </div>


                    </div>
                </>
            }
        </div>
    );
};

export default UnternehmenDetails;