import React from "react";
import styles from '../../scss/AvatarBubble.module.scss';
import '../../scss/defaultFonts.scss';
import {Link} from "react-router-dom";

interface AvatarBubbleProps {
    imagepath: string;
    name: string;
    companyName: string;
}

const AvatarBubble = (props: AvatarBubbleProps) => {
    const target = `/unternehmen/` + props.companyName.toLowerCase().replaceAll(' ', '-').replaceAll('%20', '-');
    const imagePathSmall = props.imagepath ? props.imagepath.includes('https') ? props.imagepath :
        'https://squidex.inseciacloud.com/api/assets/silicon-sanssouci/' +
        props.imagepath +
        '/sprind.png?height=202&width=202&mode=Pad' : '';
    const imagePathLarge = props.imagepath ? props.imagepath.includes('https') ? props.imagepath :
        'https://squidex.inseciacloud.com/api/assets/silicon-sanssouci/' +
        props.imagepath +
        '/sprind.png?height=404&width=404&mode=Pad' : '';

    return (
    <>  
        <div className={styles.Container} >
            <Link className={styles.AvatarContainerLink} to={target}>
                <div
                    className={styles.AvatarContainer}
                >
                    {props.imagepath && props.imagepath !== 'placeholder' &&
                        <img
                            srcSet={imagePathSmall + ' 1x, ' + imagePathLarge + ' 2x'}
                            src={imagePathSmall} alt={'Avatar Image'}
                        />
                    }
                </div>
            </Link>
            <div className={styles.AvatarContainerTextBox}>
                <p className="accent">{props.name}</p>
            </div>
        </div>
    </>
    );
};

export default AvatarBubble;
