import React, {useEffect, useState} from "react";
import { Link } from 'react-router-dom';
import {fetchStatutePage} from '../../helperFunctions/squidexFetchApi';
import styles from '../../../scss/LandingPage.module.scss';

const Satzung = () => {
    const [data, setData] = useState<any>([]);
    useEffect(() => {
        fetchStatutePage().then(setData);
    }, []);
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
    <div className={styles.SubPage}>
        <h2 className='centerText' dangerouslySetInnerHTML={{__html: data?.title?.iv.replaceAll('\n', '<br />')}} />
        <h3 className='centerText' dangerouslySetInnerHTML={{__html: data?.subtitle?.iv.replaceAll('\n', '<br />')}} />
        <div dangerouslySetInnerHTML={{__html: data?.text?.iv}} />

        <p>
            Sie fühlen sich angesprochen? Dann&nbsp;

            <Link
                to='/mitglied-werden'
            >
                werden Sie doch Mitglied im Silicon Sanssouci e.V.
            </Link>
        </p>
    </div>
    );
};

export default Satzung;