import React, {useEffect, useState} from 'react';
import AvatarBubble from '../../common/AvatarBubble';
import {fetchCompanys} from '../../helperFunctions/squidexFetchApi';
import styles from '../../../scss/LandingPage.module.scss';
import '../../../scss/defaultFonts.scss';

const KoepfePage = () => {
    const [companys, setCompanys] = useState<any>([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchCompanys().then(setCompanys);
    }, []);

    return (
    <div className={styles.SubPage}>
        <h2 className='centerText'>
            Köpfe
        </h2>
        <h3 className='centerText'>
            So wie hinter jedem Unternehmen immer auch mindestens ein kluger Kopf steckt, so stehen
            auch hinter Silicon Sanssouci innovative Geschäftsführer, die dem Potsdamer IKT-Netzwerk
            Gesicht und Stimme verleihen.
        </h3>

        <div id='AvatarBubbleContainerId' className={styles.KoepfeContainerAll}>
            {companys.map((company: any) =>
                <AvatarBubble
                    name={company.data.head.iv.name}
                    imagepath={company.data.head.iv.image ? company.data.head.iv.image[0] : 'placeholder'}
                    companyName={company.data.name.iv}
                />
            )}
        </div>
    </div>
    );
};

export default KoepfePage;