import React, {useEffect, useState} from 'react';
import AvatarPage from './LandingPages/AvatarPage';
import EngagementPage from './LandingPages/EngagementPage';
import StartPage from './LandingPages/StartPage';
import CompanyPage from './LandingPages/CompanyPage';
import MissionPage from './LandingPages/MissionPage';
import {fetchCompanys} from '../helperFunctions/squidexFetchApi';

const LandingPage = () => {
    const [companys, setCompanys] = useState<any>([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchCompanys().then(setCompanys);
    }, []);

    const shuffle = ([...arr]) => {
        let m = arr.length;
        while (m) {
            const i = Math.floor(Math.random() * m--);
            [arr[m], arr[i]] = [arr[i], arr[m]];
        }
        return arr;
    };

    return (
    <>
        <div>
            <StartPage companys={companys}/>
            <MissionPage />
            <CompanyPage companys={shuffle(companys)}/>
            <AvatarPage companys={shuffle(companys)}/>
            <EngagementPage />
        </div>
    </>
    );
};

export default LandingPage;
