import React, {useEffect, useState} from 'react';
import AvatarBubble from '../../common/AvatarBubble';
import {fetchMainPageHeads} from '../../helperFunctions/squidexFetchApi';
import styles from '../../../scss/LandingPage.module.scss';
import buttonStyles from '../../../scss/Buttons.module.scss';
import '../../../scss/defaultFonts.scss';

interface AvatarPageProps {
    companys: any;
}

const AvatarPage = (props: AvatarPageProps) => {
    const [data, setData] = useState<any>([]);
    useEffect(() => {
        fetchMainPageHeads().then(setData);
    }, []);

    return (
    <>
        <div className={styles.Page}>
            <div className={styles.KoepfePage}>
                <h2 id={'koepfe'} dangerouslySetInnerHTML={{__html: data?.title?.iv.replaceAll('\n', '<br />')}} />
                <div dangerouslySetInnerHTML={{__html: data?.text?.iv}} />
            </div>

            <div id='AvatarBubbleContainerId' className={styles.KoepfeContainer}>
                {props.companys.map((company: any) =>
                    <AvatarBubble
                        key={company.data.id.iv}
                        name={company.data.head.iv.name}
                        imagepath={company.data.head.iv.image ? company.data.head.iv.image[0] : 'placeholder'}
                        companyName={company.data.name.iv}
                    />
                )}
            </div>

            <div className={buttonStyles.ButtonContainerCenter}>
                <button
                    id='AvatarBubbleButtonId'
                    className={buttonStyles.DefaultButton}
                    onClick={() => {
                        var element = document.getElementById('AvatarBubbleContainerId');
                        if( element?.className.includes('KoepfeContainerShowMore') ){
                            element.classList.remove(styles.KoepfeContainerShowMore);
                            document.getElementById('AvatarBubbleButtonId')!.innerHTML = 'Zeige Alles';
                        } else {
                            element?.classList.add(styles.KoepfeContainerShowMore);
                            document.getElementById('AvatarBubbleButtonId')!.innerHTML = 'Zeige weniger';
                        }
                    }}
                    disabled={false}
                >
                    Zeige Alles
                </button>
            </div>
        </div>
    </>
    );
};

export default AvatarPage;
