import React from "react";
import styles from '../../../../scss/LandingPage.module.scss';
import '../../../../scss/defaultFonts.scss';
import {useNavigate} from "react-router-dom";

interface ProjekteArticleProps {
    title: string;
    imagepath: string;
    projectName: string;
}
const ProjektePageArticle = (props: ProjekteArticleProps) => {
    const navigate = useNavigate();
    const target = `/engagement/` + props.projectName.toLowerCase().replaceAll(' ', '-').replaceAll('%20', '-');

    const imagePathSmall = props.imagepath.includes('https') ? props.imagepath :
        'https://squidex.inseciacloud.com/api/assets/silicon-sanssouci/' +
        props.imagepath +
        '/sprind.png?height=202&mode=Pad';
    const imagePathLarge = props.imagepath.includes('https') ? props.imagepath :
        'https://squidex.inseciacloud.com/api/assets/silicon-sanssouci/' +
        props.imagepath +
        '/sprind.png?height=404&mode=Pad';

    return (
        <>
            <div
                className={styles.ProjektePageArticle}
                onClick={() => navigate(target)}
            >
                <img srcSet={imagePathSmall + ' 1x, ' + imagePathLarge + ' 2x'} src={imagePathSmall} alt={'Project Logo'}/>
                <h3>{props.title}</h3>
            </div>
        </>
    );
};

export default ProjektePageArticle;